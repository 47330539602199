import React, { useContext, useEffect, useState } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ticketsApi from "./ticketsApi";
import { Badge, Container, Dropdown, DropdownButton, ListGroup } from "react-bootstrap";
import { Status, Ticket } from "./types";
import { useNavigate, Link } from "react-router-dom";
import { statusColor } from "./helpers";
import { StatusesContext } from "./context";


function TicketsList(props: { tickets: Ticket[]; total: number; }) {
    const navigate = useNavigate();

    return (<Row>
        <Col>
            <Row>
                <Col>
                    <strong>{props.tickets.length}/{props.total} are showing</strong>
                </Col>
            </Row>
            <Row className="my-2" />
            <Row>
                <Col>
                    <ListGroup as="ol">
                        {props.tickets.map(ticket => {
                            return <Link
                                key={ticket.id}
                                to={"/tickets/" + ticket.id}
                                style={{"textDecoration": "unset"}}
                            >
                                <ListGroup.Item
                                    className="ticket-list-item"
                                    action
                                    as="li"
                                
                                >
                                    <Row>
                                        <Col sm="auto">
                                            <strong>
                                                <Badge
                                                    style={{minWidth: "100px"}} 
                                                    className="me-2"
                                                    bg={statusColor(ticket.status_name)}
                                                >
                                                    {ticket.status_name}
                                                </Badge>
                                            </strong>
                                        </Col>
                                        <Col>
                                            {ticket.title || "[unnamed]"}
                                        </Col>
                                        <Col sm="auto" title={`Tags: ${ticket.tags_detailed.map(t => t.name).join(", ")}`}>
                                            {ticket.tags_detailed.map(tag => {
                                                return (<small key={tag.id} className="ms-auto">
                                                    <div
                                                        style={{background: tag.hex_color}} 
                                                        className="badge ms-1"
                                                    >
                                                        {tag.name}
                                                    </div>
                                                </small>);
                                            })}
                                        </Col>
                                        <Col sm="auto" title={`Topic: ${ticket.topic_detailed?.text}`}>
                                            <Badge
                                                bg="secondary"
                                                style={{minWidth: "120px"}} 
                                            >
                                                {ticket.topic_detailed?.text ?? ""}
                                            </Badge>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            </Link>;
                        })}
                    </ListGroup>
                </Col>
            </Row>
        </Col>
    </Row>);

}

export default TicketsList;


import React, { useContext, useEffect, useState } from "react";

import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import ticketsApi from "./ticketsApi";
import TicketsList from "./TicketsList";
import { Ticket, Post, Status } from "./types";
import { useNavigate, useParams } from "react-router-dom";
import PostView from "./PostView";
import { Container, Dropdown, Form, InputGroup, SplitButton } from "react-bootstrap";
import { StatusesContext } from "./context";
import { statusColor } from "./helpers";

function TicketNew() {
    const navigate = useNavigate();
    const [title, setTitle] = useState("");
    const [email, setEmail] = useState<string>();
    const [uuid, setUuid] = useState<string>();

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        ticketsApi.ticketsCreate(title, email, uuid).then(resp => {
            if (resp.id) {
                navigate("/tickets/" + resp.id);
            } else {
                console.warn(resp);
                alert("something went wrong. check console");
            }
        });

    };

    return <Container>
        <Row>
            <Col>
                <Form onSubmit={onSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Title</Form.Label>
                        <Form.Control onInput={(e) => setTitle(e.currentTarget.value)} required placeholder="Title" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Customer email</Form.Label>
                        <Form.Control onInput={(e) => setEmail(e.currentTarget.value)} type="email" placeholder="Customer email" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Customer UUID</Form.Label>
                        <Form.Control onInput={(e) => setUuid(e.currentTarget.value)} placeholder="Customer UUID" />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Create
                    </Button>
                </Form>
            </Col>
        </Row>
    </Container>;
}

export default TicketNew;

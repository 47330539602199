import React, { createContext } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Home from "./Home";
import reportWebVitals from "./reportWebVitals";

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import TicketListPage from "./TicketListPage";
import Auth from "./Auth";
import TicketPage from "./TicketPage";
import { StatusesContext } from "./context";
import TicketNew from "./TicketNew";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "tickets",
                element: <TicketListPage />,
            },
            {
                path: "tickets/new",
                element: <TicketNew />,
            },
            {
                path: "tickets/:id",
                element: <TicketPage />,
            },
            {
                path: "auth",
                element: <Auth />,
            },
            {
                path: "home",
                element: <Home />,
            },

        ]
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Outlet } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sidebar from "./Sidebar";
import { StatusesContext, TopicsContext, TagsContext } from "./context";
import ticketsApi from "./ticketsApi";
import { Status, Tag, Topic } from "./types";

function App() {
    const [statuses, setStatuses] = useState<Status[]>([]);
    const [tags, setTags] = useState<Tag[]>([]);
    const [topics, setTopics] = useState<Topic[]>([]);

    useEffect(() => {
        ticketsApi.statusesList().then(statuses => {
            if (statuses?.results) {
                setStatuses(statuses.results);
            } else {
                setStatuses([]);
            }
        }).catch(console.warn);
        ticketsApi.tagsList().then(tags => {
            if (tags?.results) {
                setTags(tags.results);
            } else {
                setTags([]);
            }
        }).catch(console.warn);
        ticketsApi.topicsList().then(topics => {
            if (topics?.results) {
                setTopics(topics.results);
            } else {
                setTopics([]);
            }
        }).catch(console.warn);
    }, []);

    // if (!statuses) {
    //     return <Row><Col>loading statuses...</Col></Row>;
    // }

    return (
        <TagsContext.Provider value={tags}>
            <TopicsContext.Provider value={topics}>
                <StatusesContext.Provider value={statuses}>
                    <>
                        <Row className="g-0">
                            <Col>
                                <Sidebar />
                            </Col>
                        </Row>
                        <Container fluid style={{ marginTop: "76px" }}>
                            <Row>
                                <Col>
                                    <div id="detail">
                                        <Outlet />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </>
                </StatusesContext.Provider>
            </TopicsContext.Provider>
        </TagsContext.Provider>
    );
}

export default App;

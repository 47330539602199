import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

function Auth() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();

        fetch("/api/api-token-auth/", {
            "headers": {
                "content-type": "application/json",
                "referrer": "http://127.0.0.1:8160/api-auth/login/?next=/",
                "referrerPolicy": "same-origin",
            },
            "body": JSON.stringify({username, password}),
            "method": "POST",
            "mode": "cors",
            "credentials": "include"
        })
            .then(res => res.json())
            .then((res) => {
                if (res.token) {
                    localStorage["token"] = res.token;
                    location.href = "/tickets";
                } else {
                    alert(JSON.stringify(res));
                }
            }).catch(err => {
                console.error(err);
                alert("something went wrong. check console");
            });
    };

    return (
        <Row>
            <Col>
                <Form onSubmit={onSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Username</Form.Label>
                        <Form.Control onChange={e => setUsername(e.target.value)} placeholder="Enter username" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control onChange={e => setPassword(e.target.value)} type="password" placeholder="Password" />
                    </Form.Group>
                    <Button variant="primary" type="submit">
        Submit
                    </Button>
                </Form>

            </Col>
        </Row>
    );
}

export default Auth;

import React, { useEffect, useState, useMemo } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Ticket, Post } from "./types";
import _ from "classnames";

function Attachment({url}: { url :string}) {
    const isImage = useMemo(() => {
        if (!url) {
            return false;
        }

        return /\.(jpe?g)|(png)$/.test(url);

    }, [url]);

    return <div className="attachment-container">
        <a target="_blank" href={url} rel="noreferrer">
            {isImage && <img src={url} loading="lazy" />}
            {!isImage && "Вложение"}
        </a>
    </div>;


}

function PostView (props: { post: Post, onDelete: () => void }) {
    const date = useMemo(() => {
        return new Date(props.post.created_at);
    }, [props.post.created_at]);

    const onDelete = () => {
        if (confirm("delete?")) {
            return props.onDelete();
        }
    };

    return (
        <Row>
            <Col>
                <Row>
                    <Col className={_(["d-none"], {"d-md-block": !props.post.is_from_client})}>
                        
                    </Col>
                    <Col
                        className={_(
                            ["text-start", "ticket-post", "text-white", "p-3", "rounded", "my-2", "w-auto"],
                            {
                                "bg-secondary": props.post.is_from_client,
                                "from-client": props.post.is_from_client,
                                "bg-primary": !props.post.is_from_client,
                                "bg-secondary-subtle": props.post.is_staff_comment,
                            }
                        )}
                        md="auto">
                        <Row>
                            <Col style={{whiteSpace: "pre-line"}}>
                                {props.post.inner_body}
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{whiteSpace: "pre-line"}}>
                                {props.post.attachment && <Attachment url={props.post.attachment} />}
                            </Col>
                        </Row>
                        <Row className="text-start">
                            <Col md="auto">
                                <i><small style={{ color: "#bbbbbb" }}>{date.toLocaleString()}</small></i>
                            </Col>
                            {!props.post.is_from_client && !props.post.is_staff_comment && <Col
                                md="auto"
                                title="whether or not the message has been read by client"
                            >
                                {props.post.is_read_by_client && <strong>READ</strong>}
                            </Col>}
                            {props.post.is_staff_comment && <Col md="auto" >
                                hidden message
                            </Col>}
                            {props.post.customer_satisfaction !== 0 && <Col md="auto" >
                                {props.post.customer_satisfaction == 1 && "⭐️"}
                                {props.post.customer_satisfaction == 2 && "⭐️⭐️"}
                                {props.post.customer_satisfaction == 3 && "⭐️⭐️⭐️"}
                                {props.post.customer_satisfaction == 4 && "⭐️⭐️⭐️⭐️"}
                                {props.post.customer_satisfaction == 5 && "⭐️⭐️⭐️⭐️⭐️"}
                            </Col>}
                            {
                                !props.post.is_staff_comment
                                    && !props.post.is_from_client
                                    && props.post.is_sent &&
                                        <Col md="auto"><strong>SENT</strong></Col>
                            }
                            <Col md="auto" onClick={onDelete} style={{ cursor: "pointer" }}>
                                <small><strong><span style={{ textDecoration: "underline", color: "pink" }}>DELETE</span></strong></small>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>

    );
}

export default PostView;

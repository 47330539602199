import React, { useContext, useEffect, useRef, useState } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ticketsApi from "./ticketsApi";
import TicketsList from "./TicketsList";
import { Status, Tag, Ticket, Topic } from "./types";
import { Button, Container, Dropdown, DropdownButton, Form, InputGroup, Pagination } from "react-bootstrap";
import { StatusesContext, TagsContext, TopicsContext } from "./context";
import { Link } from "react-router-dom";
import { parseCookie } from "./helpers";

const PER_PAGE = 100;

let searchTimer: any = null;
let refreshInterval: any = null;

function TicketListPage() {
    const [tickets, setTickets] = useState<Ticket[]>([]);
    const [page, _setPage] = useState(0);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);
    const [total, setTotal] = useState(0);
    const [pending, setPending] = useState(true);
    const [searchInput, _setSearchInput] = useState("");
    const searchInputRef = useRef("");

    const setPage = (newPage: number) => {
        _setPage(newPage);
        if (newPage !== page) {
            document.documentElement.scrollTo(0, 0);
        }
    };

    const setSearchInput = (val: string) => {
        searchInputRef.current = val;
        return _setSearchInput(val);
    };

    let defaultStatus;
    try {
        defaultStatus = JSON.parse(localStorage["defaultStatus"]);
    } catch(_) { //
    }

    const [statusFilter, _setStatusFilter] = useState<Status|undefined>(defaultStatus);
    const [tagFilter, setTagFilter] = useState<Tag[]>([]);
    const [topicFilter, _setTopicFilter] = useState<Topic>();
    const [starsFilter, _setStarsFilter] = useState<[number, number]>();
    const statusFilterRef = useRef<Status|undefined>(defaultStatus);
    const topicFilterRef = useRef<Topic>();
    const starsFilterRef = useRef<[number, number]>();
    const tagFilterRef = useRef<Tag[]>([]);

    const setStatusFilter = (val: Status | undefined) => {
        statusFilterRef.current = val;
        return _setStatusFilter(val);
    };

    const setTopicFilter = (val?: Topic | undefined) => {
        topicFilterRef.current = val;
        return _setTopicFilter(val);
    };

    const setStarsFilter = (val?: [number, number] | undefined) => {
        starsFilterRef.current = val;
        return _setStarsFilter(val);
    };

    const addTagFilter = (val: Tag) => {
        tagFilterRef.current.push(val);
        return setTagFilter([...tagFilterRef.current]);
    };

    const removeTagFilter = (val: Tag) => {
        tagFilterRef.current = tagFilterRef.current.filter(t => t.id !== val.id);
        return setTagFilter([...tagFilterRef.current]);
    };

    const clearTagFilter = () => {
        tagFilterRef.current = [];
        return setTagFilter([]);
    };

    const statuses = useContext(StatusesContext);
    const tags = useContext(TagsContext);
    const topics = useContext(TopicsContext);

    useEffect(() => {
        const newCount = tickets.filter(t => t.status_name === "New").length;
        if (newCount > 0) {
            document.title = `${newCount} | Tickets | Billing Admin`;
        } else {
            document.title = "Tickets | Billing Admin";
        }

        return () => {
            document.title = "Tickets | Billing Admin";
        };
    }, [tickets]);

    const refresh = () => {
        const search = searchInputRef.current;
        const statusFilter = statusFilterRef.current;
        const tagsFilter = tagFilterRef.current.map(t => t.id);
        const topicFilter = topicFilterRef.current?.id;
        const starsFilter = starsFilterRef.current;

        ticketsApi.ticketsList(
            search?.trim() ?? "",
            statusFilter?.id,
            tagsFilter,
            topicFilter,
            starsFilter?.[0] ?? 0,
            starsFilter?.[1] ?? 5,
            PER_PAGE,
            PER_PAGE*page,
            "-updated_at",
        ).then(ticketsPage => {
            if (!ticketsPage.results) {
                return;
            }
            setHasNext(!!ticketsPage.next);
            setHasPrev(!!ticketsPage.previous);
            setTotal(ticketsPage.count);
            setTickets(ticketsPage.results);

            setPending(false);
        });
    };

    useEffect(() => {
        refresh();
        refreshInterval = setInterval(() => {
            refresh();
        }, 5000);
        return () => {
            clearInterval(refreshInterval);
        };
    }, [statusFilter, topicFilter, starsFilter, tagFilter, page, pending]);


    const setStatus = (status?: Status) => {
        localStorage["defaultStatus"] = JSON.stringify(status);
        setStatusFilter(status);
        setPage(0);
    };

    const addTag = (tag: Tag) => {
        addTagFilter(tag);
        setPage(0);
    };
    const removeTag = (tag: Tag) => {
        removeTagFilter(tag);
        setPage(0);
    };
    const clearTags = () => {
        clearTagFilter();
        setPage(0);
    };

    const setTopic = (topic?: Topic) => {
        setTopicFilter(topic);
        setPage(0);
    };

    const setStars = (val?: [number, number]) => {
        setStarsFilter(val);
        setPage(0);
    };

    const onSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const skipTimer = e.target.value !== searchInputRef.current && e.target.value === "";

        setSearchInput(e.target.value);

        clearTimeout(searchTimer);

        if (skipTimer) {
            refresh();
            return;
        }

        searchTimer = setTimeout(() => {
            refresh();
        }, 800);
    };

    return (
        <Container>
            <Row>
                <Col>
                    <Row className="mb-2 ml-2">
                        <Col>
                            <Link to="/tickets/new"><Button>Create ticket</Button></Link>
                        </Col>
                        <Col sm="auto">
                            <Row>
                                {tags.map(tag => {
                                    const selected = !!tagFilter.find(t => t.id === tag.id);
                                    return (
                                        <Col sm="auto" key={tag.id}>
                                            <div
                                                style={{ background: tag.hex_color, opacity: selected ? "1" : "0.4" }} 
                                                className="badge ms-1 badge-hover"
                                                onClick={() => selected ? removeTag(tag) : addTag(tag)}
                                            >
                                                {tag.name}
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Col>
                        <Col sm="auto">
                            <DropdownButton
                                id="dropdown-basic-button"
                                variant={topicFilter ? "primary" : "secondary"}
                                title={`Topic: ${topicFilter?.text ?? "All"}`}
                            >
                                <Dropdown.Item onClick={() => setTopic()}>All</Dropdown.Item>
                                {topics.map(topic => {
                                    return <Dropdown.Item key={topic.id} onClick={() => setTopic(topic)}>{topic.text}</Dropdown.Item>;
                                })}
                            </DropdownButton>
                        </Col>
                        <Col sm="auto">
                            <DropdownButton
                                id="dropdown-basic-button"
                                variant={starsFilter ? "primary" : "secondary"}
                                title={`Stars: ${starsFilter ? starsFilter[0] + "-" + starsFilter[1] : "All"}`}
                            >
                                <Dropdown.Item onClick={() => setStars()}>All</Dropdown.Item>
                                {new Array(5).fill(0).map((_, idx) => {
                                    return <Dropdown.Item key={idx} onClick={() => setStars([1, idx+1])}>⭐️-{"⭐️".repeat(idx+1)}</Dropdown.Item>;
                                })}
                                {new Array(5).fill(0).map((_, idx) => {
                                    return <Dropdown.Item key={idx} onClick={() => setStars([idx+1, idx+1])}>{"⭐️".repeat(idx+1)}</Dropdown.Item>;
                                })}
                            </DropdownButton>
                        </Col>
                        <Col sm="auto">
                            <DropdownButton
                                id="dropdown-basic-button"
                                variant={statusFilter ? "primary" : "secondary"}
                                title={`Status: ${statusFilter?.status ?? "All"}`}
                            >
                                <Dropdown.Item onClick={() => setStatus()}>All</Dropdown.Item>
                                {statuses.map(status => {
                                    return <Dropdown.Item key={status.id} onClick={() => setStatus(status)}>{status.status}</Dropdown.Item>;
                                })}
                            </DropdownButton>
                        </Col>
                        <Col sm="5">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="search-input"
                                    value={searchInput}
                                    onChange={onSearchInput}
                                />
                                <Button onClick={() => {
                                    setSearchInput("");
                                    refresh();
                                }} variant="outline-secondary" id="search-input">
                                    Clear
                                </Button>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {!pending && <TicketsList tickets={tickets} total={total} />}
                            {pending && <h4 className="text-center">loading...</h4>}
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            {hasNext && <Pagination size="sm">
                                {new Array(Math.ceil(total/PER_PAGE)).fill(0).map((_, i) => {
                                    return <Pagination.Item onClick={() => setPage(i)} key={i} active={i === page}>
                                        {i}
                                    </Pagination.Item>;
                                })}
                            </Pagination>}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default TicketListPage;

import React, { useContext, useEffect, useState } from "react";

import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import ticketsApi from "./ticketsApi";
import TicketsList from "./TicketsList";
import { Ticket, Post, Status, Tag, Topic } from "./types";
import { useParams } from "react-router-dom";
import PostView from "./PostView";
import { Container, Dropdown, DropdownButton, Form, InputGroup, SplitButton } from "react-bootstrap";
import { StatusesContext, TagsContext, TopicsContext } from "./context";
import { statusColor } from "./helpers";
import { MAIN_SITE_HOST } from "./vars";


const billingUrl = (clientId: string) => {
    return `https://superuser.${MAIN_SITE_HOST}/api/admin/stargazer_api/customer/${clientId}/change/`;
};

// TODO: implement
// const billingProductsUrl = (clientId: string) => {
//     return `https://superuser.hostvds.com/api/admin/stargazer_api/customer/${clientId}/change/`;
// };
// const billingTransactionsUrl = (clientId: string) => {
//     return `https://superuser.hostvds.com/api/admin/stargazer_api/customer/${clientId}/change/`;
// };

let refreshTimer: any = -1;
function TicketPage() {
    const { id } = useParams();
    const [ticket, setTicket] = useState<Ticket>();
    const [posts, setPosts] = useState<Post[]>([]);
    const [total, setTotal] = useState(0);
    const [inputMessage, setInputMessage] = useState("");
    const [pending, setPending] = useState(true);
    const [changeStatus, setChangeStatus] = useState(false);
    const [pendingStatusChange, setPendingStatusChange] = useState(false);
    const [sendPending, setSendPending] = useState(false);
    const statuses = useContext(StatusesContext);
    const tags = useContext(TagsContext);
    const topics = useContext(TopicsContext);
    const [_key, _setKey] = useState(0);
    const [file, setFile] = useState<File | null>();

    const scrollToBottom = (noAnimation = false) => {
        if (noAnimation) {
            document.documentElement.style.scrollBehavior = "unset";
        }
        requestAnimationFrame(() => {
            document.documentElement.scrollBy(0, 1000000);
            if (noAnimation) {
                setTimeout(() => {
                    document.documentElement.style.scrollBehavior = "";
                });
            }
        });
    };

    const refresh = async () => {
        if (!id) {
            throw Error("id not found");
        }


        const [ticket, postsPage] = await Promise.all([
            ticketsApi.ticketsGet(id),
            ticketsApi.postsList(id, 1000, 0),
        ]);

        setTicket(ticket);
        postsPage.results.reverse();
        setPosts(postsPage.results);
        setTotal(postsPage.count);
        setPending(false);
    };

    useEffect(() => {
        if (!ticket && id) {
            document.title = `#${id.split("-")[0].substring(0, 4).toUpperCase()} | Billing Admin`;
        }
        if (ticket) {
            document.title = `#${ticket.short_id.substring(0, 4).toUpperCase()} | ${ticket?.title.substring(0, 100)} | Billing Admin`;
        }

        return () => {
            document.title = "Tickets | Billing Admin";
        };
    }, [ticket]);

    useEffect(() => {
        refresh().then(() => {
            scrollToBottom(true);
        });

        refreshTimer = setInterval(() => {
            refresh();
        }, 5 * 1000);

        return () => {
            clearInterval(refreshTimer);
        };
    }, []);

    const send = (asComment = false) => {
        if (!ticket) {
            throw Error("no ticket");
        }

        if (!inputMessage.trim().length && !file) {
            return;
        }

        return ticketsApi.postsCreate({
            body: inputMessage.trim(),
            attachment: file ?? undefined,
            is_from_client: false,
            is_staff_comment: asComment,
            is_read_by_client: false,
            is_read_by_staff: false,
            is_sent: false,
            ticket: ticket?.id,
        }).then(() => {
            refresh().then(() => {
                requestAnimationFrame(() => {
                    scrollToBottom(false);
                });
            });

            setFile(undefined);
            _setKey(Date.now());
            setInputMessage("");
        }).finally(() => {
            setSendPending(false);
        });
    };


    const onDelete = async (postId: number) => {
        await ticketsApi.postsDelete(postId);
        refresh();
    };

    const onStatusClick = async (status: Status) => {
        if (pendingStatusChange) {
            return;
        }

        if (!ticket) {
            throw Error("no ticket");
        }

        setPendingStatusChange(true);
        await ticketsApi.ticketsUpdate(ticket?.id, { status: status.id });
        await refresh();
        setChangeStatus(false);
        setPendingStatusChange(false);

    };

    const removeTag = async (tag: Tag) => {
        if (!ticket) return;
        await ticketsApi.ticketsUpdate(ticket?.id, { tags: ticket.tags.filter(id => id !== tag.id) });
        await refresh();
    };
    const addTag = async (tag: Tag) => {
        if (!ticket) return;
        const tags = [...ticket.tags];
        tags.push(tag.id);
        await ticketsApi.ticketsUpdate(ticket?.id, { tags });
        await refresh();
    };

    const setTopic = async (topic: Topic) => {
        if (!ticket) return;
        await ticketsApi.ticketsUpdate(ticket?.id, { topic: topic.id });
        await refresh();
    };


    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    return (<Container>
        <Row >
            {pending && <Col><h4 className="text-center">Loading...</h4></Col>}
            {!pending && ticket && <Col
                className="text-center ticket-page"
            >
                <Row className="mb-3 ticket-page__info">
                    <Col>
                        <Row className="mb-3">
                            <Col className="text-center">
                                <h3>Ticket#{ticket?.short_id}: {ticket?.title}</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center" md="12">
                                <a
                                    target="_blank"
                                    href={billingUrl(ticket.billing_client_id)}
                                    rel="noreferrer"
                                >Customer {ticket.billing_client_id}</a>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center" md="12">
                                {new Date(ticket.created_at).toLocaleString()}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center" md="12">
                                <Badge
                                    onClick={() => setChangeStatus(!changeStatus)}
                                    style={{ cursor: "pointer" }}
                                    bg={statusColor(ticket.status_name)}
                                >Status: {pendingStatusChange ? "loading..." : ticket.status_name}</Badge>
                            </Col>
                            <Col
                                style={{ minHeight: "24px" }}
                                className="text-center mt-2"
                                md="12"
                            >
                                <Row style={{ display: changeStatus ? "" : "none" }}>
                                    <Col className="text-center">
                                        {statuses.map(status => {
                                            return <Badge
                                                key={status.id}
                                                className="mx-1"
                                                style={{ cursor: "pointer" }}
                                                bg={statusColor(status.status)}
                                                onClick={() => onStatusClick(status)}
                                            >{status.status}</Badge>;
                                        })}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                style={{ minHeight: "24px" }}
                                className="text-center mt-2"
                                md="12"
                            >
                                <Row>
                                    <Col className="text-center">
                                        {tags.map(tag => {
                                            console.log(tag);
                                            const selected = !!ticket.tags.find(id => id === tag.id);
                                            return <div
                                                key={tag.id}
                                                style={{ background: tag.hex_color, opacity: selected ? "1" : "0.4" }}
                                                className="badge ms-1 badge-hover"
                                                onClick={() => selected ? removeTag(tag) : addTag(tag)}
                                            >
                                                {tag.name}
                                            </div>;
                                        })}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="auto" className="ms-auto me-auto mt-2">
                                <DropdownButton
                                    id="dropdown-basic-button"
                                    title={"Topic: " + ticket?.topic_detailed?.text}
                                >
                                    {topics.map(topic => {
                                        return <Dropdown.Item key={topic.id} onClick={() => setTopic(topic)}>{topic.text}</Dropdown.Item>;
                                    })}
                                </DropdownButton>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-4 ticket-page__posts">
                    <Col className="text-center">
                        {posts.map(post => {
                            return <PostView key={post.id} onDelete={() => onDelete(post.id)} post={post} />;
                        })}
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <Form>
                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Control key={_key} onChange={handleFileChange} type="file" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        value={inputMessage}
                                        disabled={sendPending}
                                        onInput={(e) => setInputMessage((e.target as any)?.value)}
                                        placeholder="Message..."
                                        as="textarea"
                                        rows={10}
                                    />
                                    <Button disabled={sendPending} onClick={() => send(false)} variant="success" id="button-addon2">
                                        {sendPending ? "sending..." : "Send reply"}
                                    </Button>
                                    <Button disabled={sendPending} onClick={() => send(true)} variant="secondary" id="button-addon2">
                                        {sendPending ? "sending..." : "Comment"}
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                        </Form>

                    </Col>
                </Row>
            </Col>}
        </Row>
    </Container>);
}

export default TicketPage;

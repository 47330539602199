export const statusColor = (status: string) => {
    status = status.toLocaleLowerCase();

    if (status === "new") {
        return "danger";
    }

    if (status === "done") {
        return "success";
    }

    if (status === "replied") {
        return "dark";
    }

    if (status === "in progress") {
        return "primary";
    }

    return "secondary";
};

export const parseCookie = () => {
    try {
        return document.cookie
            .split(";")
            .map(v => v.split("="))
            .reduce((acc: any, v) => {
                acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
                return acc;
            }, {});

    } catch(err) {
        console.error(err);
    }
};

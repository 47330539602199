import { parseCookie } from "./helpers";
import { Ticket, Post, Status, Page, Topic, Tag } from "./types";

const f = (url: string, method: "POST"|"GET"|"PUT"|"PATCH"|"DELETE" = "GET", data?: unknown|FormData) => {
    let body = data;
    let contentType;
    if (!(body instanceof FormData)) {
        body = body ? JSON.stringify(body) : undefined;
        contentType = "application/json";
    }
    const headers: any = {
        "Authorization": "Token " + localStorage["token"],
        "X-CSRFToken": parseCookie()?.["csrftoken"],
    };

    if (contentType) {
        headers["Content-Type"] = contentType;
    }

    return fetch(url, {
        "body": body as any,
        "method": method,
        headers,
        "mode": "cors",
        "credentials": "include"
    }).then(resp => {
        if (resp.status >= 400) {
            return resp.text().then(() => { throw Error(resp.statusText);});
        }
        if (method !== "DELETE") {
            try {
                return resp.json();
            } catch(err) {
                console.warn(err);
            }
        }
    });
};

export default {
    ticketsList: (
        search="",
        statusId?: number|undefined|null,
        tagIds?: number[],
        topicId?: number,
        customerSatisfactionMin?: number,
        customerSatisfactionMax?: number,
        limit = 50,
        offset = 0,
        ordering?: string,
    ): Promise<Page<Ticket>> => {
        let url = `/api/tickets/?search=${search}&limit=${limit}&offset=${offset}&ordering=${ordering ?? "-created_at"}`;
        if (statusId) {
            url = url + `&status=${statusId}`;
        }

        if (topicId) {
            url = url + `&topic__id=${topicId}`;
        }

        if (customerSatisfactionMin && customerSatisfactionMax) {
            url = url +
                `&posts__customer_satisfaction__range=${customerSatisfactionMin},${customerSatisfactionMax}`;
        }

        if (tagIds?.length) {
            url = url + `&tags__id__in=${tagIds.join(",")}`;
            // tagIds.forEach(id => {
            //     url = url + `&tags__id=${id}`;
            // });
        }

        return f(url);
    },
    ticketsCreate: (title: string, email?: string, uuid?: string): Promise<Ticket> => {
        return f("/api/tickets/", "POST",  { title, email, billing_client_id: uuid });
    },
    ticketsUpdate: (id: string, body: unknown): Promise<Ticket> => {
        return f(`/api/tickets/${id}/`, "PATCH",  body);
    },
    ticketsGet: (id: string): Promise<Ticket> => {
        return f(`/api/tickets/${id}/`);
    },
    postsList: (ticket_id: string, limit = 500, offset = 0): Promise<Page<Post>> => {
        return f(`/api/posts/?ticket=${ticket_id}&limit=${limit}&offset=${offset}`);
    },
    postsCreate: (post: {body: string; attachment?: File} & Omit<Post, "attachment"|"customer_satisfaction"|"id"|"created_at"|"updated_at"|"inner_body">): Promise<Post> => {
        let body: {body: string; attachment?: File} & Omit<Post, "attachment"|"customer_satisfaction"|"id"|"created_at"|"updated_at"|"inner_body"> | FormData = post;
        if (post.attachment) {
            const formData = new FormData();

            Object.entries(post).forEach(([key, value]) => {
                if (typeof value === "string" || typeof value === "boolean") {
                    formData.append(key, value.toString());
                } else {
                    formData.append(key, value);
                }
            });
            body = formData;
        }
        return f("/api/posts/", "POST", body);
    },
    postsDelete: (id: number): Promise<unknown> => {
        return f(`/api/posts/${id}`, "DELETE");
    },
    statusesList: (limit = 500, offset = 0): Promise<Page<Status>> => {
        return f(`/api/statuses/?limit=${limit}&offset=${offset}`);
    },
    topicsList: (limit = 500, offset = 0): Promise<Page<Topic>> => {
        return f(`/api/topics/?limit=${limit}&offset=${offset}`);
    },
    tagsList: (limit = 500, offset = 0): Promise<Page<Tag>> => {
        return f(`/api/tags/?limit=${limit}&offset=${offset}`);
    },
};

